
h1, h2 {
  color: #501B1D;
  text-align: center;
}

.App {
  width: 100%;
  margin: auto;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}

.video-gallery {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  margin: auto;
  width: 99%;
  gap: 20px;
}

.video-player {
  top: 0;
  left: 0;
}

.video-description {
  text-align: center;
}